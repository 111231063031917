/*** IMPORTS FROM imports-loader ***/
var define = false;

jQuery(document).ready(function($) {


    /**
     * ########################################
     * Smooth Scroll to Anchor-Links
     * ########################################
     */
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function() {
                    // Callback after animation
                    // Must change focus!
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) { // Checking if the target was focused
                      return false;
                    } else {
                        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                    };
                });
            }
        }
    });





    /**
     * ########################################
     * Hamburger Animation
     * ########################################
     */
    $('.navbar-toggler').click(function(){
        $(this).toggleClass('is-active');
        $('.main-navigation').toggleClass('toggled');
        $('body').toggleClass("modal-open");
    });



    /**
     * ########################################
     * Close Navbar when Clicking Anywhere
     * ########################################
     */
    $(document).click(function (event) {
        var click = $(event.target);
        var _open = $(".navbar-collapse").hasClass("show");
        if (_open === true && !click.hasClass("navbar-toggler")) {
            $(".navbar-toggler").click();
        }
    });





    /**
     * ########################################
     * Global Ligthbox Options
     * ########################################
     */
    lightbox.option({
        alwaysShowNavOnTouchDevices: true,
        albumLabel: "Bild %1 von %2",
        disableScrolling: true,
        showImageNumberLabel: false,
        wrapAround: true,
        imageFadeDuration: 300,
        positionFromTop: 150,
        //fadeDuration: 600,
        //fitImagesInViewport: true,
        //maxWidth: xx,
        //maxHeight: xx,
        //resizeDuration: 300,
    });




    /**
     * ########################################
     * Collapse Space between Headline and Sub
     * ########################################
     */
    $('.sub').prev('h1', 'h2', 'h3', 'h4', 'h5', 'h6').addClass('mb-0');





    /**
     * ########################################
     * Change Accordion Icon
     * ########################################
     */
    $('.collapse')
    .on('shown.bs.collapse', function() {
      $(this)
        .parent()
        .find(".fa-angle-down")
        .removeClass("fa-angle-down")
        .addClass("fa-angle-up");
    })
    .on('hidden.bs.collapse', function() {
        $(this)
          .parent()
          .find(".fa-angle-up")
          .removeClass("fa-angle-up")
          .addClass("fa-angle-down");
    });




    /**
     * ########################################
     * Init Slick Sliders
     * ########################################
     */
    $('.slider-images').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        prevArrow:"<i class='fa fa-angle-left slick-prev'></i>",
        nextArrow:"<i class='fa fa-angle-right slick-next'></i>",
    });

    //$('.autoplay').slick('slickSetOption', 'autoplay', true, true);

    $('.slider-text').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        prevArrow:"<i class='fa fa-angle-left slick-prev'></i>",
        nextArrow:"<i class='fa fa-angle-right slick-next'></i>",
    });





    /**
     * ########################################
     * Trigger Animations when Element comes into Viewport
     * ########################################
     */
    function scrollWaypointInit( items, trigger ) {
        items.each( function() {
            var element = $(this),
                osAnimationClass = element.data("animation"),
                osAnimationDelay = element.attr('data-animation-delay');

            var trigger = ( trigger ) ? trigger : element;

            trigger.waypoint(function() {
                element.addClass(osAnimationClass);
                if (element.attr('data-animation-delay')){
                    element.addClass('delay-'+(osAnimationDelay));
                }
            },{
                triggerOnce: true,
                offset: '100%'
            });
        });
    }

    scrollWaypointInit( $('.animated') );





    /**
     * ########################################
     * Fallback for img object-fit for Older Browsers
     * ########################################
     */
    function tkObjectFit() {
    $('img.object-fit').each(function(){
        var imgSrc = $(this).attr('src');
        var fitType = 'cover';

        if($(this).data('fit-type')) {
            fitType = $(this).data('fit-type');
        }

        $(this).parent().css({ 'background' : 'transparent url("'+imgSrc+'") no-repeat center center/'+fitType, });
        $(this).remove(); });
    }

    if(!Modernizr.objectfit) {
        tkObjectFit();
    }


});

