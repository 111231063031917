/*** IMPORTS FROM imports-loader ***/
var define = false;

// Packages
import 'bootstrap';
import 'slick-carousel';
import 'waypoints';

// Includes
import './frameworks/bootstrap.js'; import './frameworks/lightbox.js'; import './frameworks/modernizr-object-fit.js'; import './frameworks/slick.js'; import './frameworks/waypoints.js';
import './includes/core.js';


